import { Gradient } from "./Gradient.js";
import { GUI } from "./lil-gui.module.min.js";
import defaults from "./defaults";

let capturer;
let input = document.createElement("input");
const state = { ...defaults };

const hs = [
  [0x2d3e50, 0x01c26e, 0xf3c40f, 0xe24c3f, 0x8f44ab],
  [0x53df83, 0x47d2e9, 0xeeeeee, 0x3f3f3f, 0x47d2e9],
  [0x4cd4b0, 0xfffce6, 0xedd834, 0xf24d16, 0x7d1424],
  [0xfe6860, 0xfe8a71, 0xf3c9bf, 0xd9bbae, 0x0c545c],
  [0xd1d5d8, 0x3498db, 0xf1c40f, 0xe74c3c, 0x1abc9c],
  [0x1f9ea3, 0xf8bd97, 0x3b0102, 0x9e5428, 0xbfb992],
  [0x4a4e4d, 0x0e9aa7, 0x3da4ab, 0xf6cd61, 0xfe8a71],
  [0x043d5d, 0x032e46, 0x0f595e, 0x23b684, 0xffffff],
];

const generate = () => {
  const e = Math.floor(Math.random() * hs.length);
  return hs[e];
};

function normalizeColor(hexCode) {
  return [((hexCode >> 16) & 255) / 255, ((hexCode >> 8) & 255) / 255, (255 & hexCode) / 255];
}

function updateInnerSizesFromState(gradient) {
  gradient.width = state.innerWidth;
  gradient.height = state.innerHeight;
  gradient.resize();
}

function updateWindowFromState() {
  const canvas = document.querySelector("#gradient-canvas");
  const gl = canvas.getContext("webgl");

  //const e = window.innerWidth * state.pixelRatio;
  //const t = window.innerHeight * state.pixelRatio;

  const e = state.width * state.pixelRatio;
  const t = state.height * state.pixelRatio;

  canvas.width = e;
  canvas.height = t;

  //canvas.style.width = window.innerWidth + 'px';
  //canvas.style.height = window.innerHeight + 'px';

  canvas.style.width = state.width + "px";
  canvas.style.height = state.height + "px";

  gl.viewport(0, 0, e, t);
}

function stateToGradientState(gradient) {
  if (typeof CCapture != "undefined")
    capturer = new CCapture({
      format: state.captureFormat,
      framerate: state.FPSCapture,
      verbose: true,
    });

  if (capturer) gradient.setCapturer(capturer);

  gradient.setSkipFrame(state.skipFrames);

  gradient.mesh.material.uniforms["u_baseColor"].value = normalizeColor(state.color1);
  gradient.mesh.material.uniforms["u_waveLayers"].value[0].value.color.value = normalizeColor(state.color2);
  gradient.mesh.material.uniforms["u_waveLayers"].value[1].value.color.value = normalizeColor(state.color3);
  gradient.mesh.material.uniforms["u_waveLayers"].value[2].value.color.value = normalizeColor(state.color4);
  updateInnerSizesFromState(gradient);
  updateWindowFromState();
}

function recursiveInitialization(gradient) {
  //wait when loaded
  if (gradient.mesh) {
    stateToGradientState(gradient);
  } else {
    window.requestAnimationFrame(() => {
      recursiveInitialization(gradient);
    });
  }
}

// Create your instance
const gradient = new Gradient();
gradient.initGradient("#gradient-canvas");
recursiveInitialization(gradient);

const functions = {
  Save: () => {
    const a = document.createElement("a");

    const stateToExport = { ...state };

    let file = new Blob([JSON.stringify(stateToExport)], { type: "text/plain" });
    a.href = URL.createObjectURL(file);
    a.download = "state.json";
    a.click();
  },

  Load: () => {
    input.type = "file";
    input.click();
  },

  ApplyLoadedParameters: () => {
    if (input.files && input.files.length > 0) {
      input.files[0].text().then((res) => {
        let newState = JSON.parse(res);
        Object.keys(newState).forEach((key) => {
          if (key == "badword") return;

          state[key] = newState[key];
          if (gui)
            gui.folders.forEach((folder) => {
              folder.controllers.forEach((controller) => {
                if (controller.property == key) controller.setValue(newState[key]);
              });
            });
        });

        //gui
      });
      input = document.createElement("input");
    }
    stateToGradientState(gradient);
  },

  Play: () => {
    gradient.play();
  },
  Pause: () => {
    gradient.pause();
  },
  MakeScreenShot: () => {
    let a = document.createElement("a");
    let canvas = document.querySelector("#gradient-canvas");
    a.href = canvas.toDataURL("image/png");
    a.download = "screenshot.png";
    a.click();
  },
  RunCapture: () => {
    capturer.start();
    gradient.runCapture();
  },

  StopCapture: () => {
    gradient.stopCapture();
    capturer.stop();
  },

  SaveCapture: () => {
    capturer.save();
  },

  Randomize: () => {
    const colors = generate();
    state.color1 = colors[0];
    state.color2 = colors[1];
    state.color3 = colors[2];
    state.color4 = colors[3];

    gradient.mesh.material.uniforms["u_baseColor"].value = normalizeColor(state.color1);
    gradient.mesh.material.uniforms["u_waveLayers"].value[0].value.color.value = normalizeColor(state.color2);
    gradient.mesh.material.uniforms["u_waveLayers"].value[1].value.color.value = normalizeColor(state.color3);
    gradient.mesh.material.uniforms["u_waveLayers"].value[2].value.color.value = normalizeColor(state.color4);

    if (gui)
      gui.folders.forEach((folder) => {
        folder.controllers.forEach((controller) => {
          if (
            controller.property == "color1" ||
            controller.property == "color2" ||
            controller.property == "color3" ||
            controller.property == "color4"
          )
            controller.setValue(state[controller.property]);
        });
      });
  },
};

let gui = new GUI();
let f = gui.addFolder("Settings");
f.add(state, "skipFrames", false, true).onChange(() => {
  gradient.setSkipFrame(state.skipFrames);
});
f.add(state, "captureFormat", ["webm", "png", "jpg"]).onChange(() => {
  if (typeof CCapture != "undefined")
    capturer = new CCapture({
      format: state.captureFormat, //"webm",
      framerate: state.FPSCapture,
      verbose: true,
    });
  if (capturer) gradient.setCapturer(capturer);
});
f.add(state, "FPSCapture", 0, 200, 1).onChange((v) => {
  if (typeof CCapture != "undefined")
    capturer = new CCapture({
      format: state.captureFormat, //"webm",
      framerate: state.FPSCapture,
      verbose: true,
    });
  if (capturer) gradient.setCapturer(capturer);
});
f.add(state, "width", 128, 4096).onChange(updateWindowFromState);
f.add(state, "height", 128, 4096).onChange(updateWindowFromState);
f.add(state, "pixelRatio", 0.1, 3).onChange(updateWindowFromState);
f.add(state, "innerWidth", 128, 4096).onChange(() => {
  updateInnerSizesFromState(gradient);
  updateWindowFromState();
});
f.add(state, "innerHeight", 128, 4096).onChange(() => {
  updateInnerSizesFromState(gradient);
  updateWindowFromState();
});
f.addColor(state, "color1").onChange(() => {
  gradient.mesh.material.uniforms["u_baseColor"].value = normalizeColor(state.color1);
});
f.addColor(state, "color2").onChange(() => {
  gradient.mesh.material.uniforms["u_waveLayers"].value[0].value.color.value = normalizeColor(state.color2);
});
f.addColor(state, "color3").onChange(() => {
  gradient.mesh.material.uniforms["u_waveLayers"].value[1].value.color.value = normalizeColor(state.color3);
});
f.addColor(state, "color4").onChange(() => {
  gradient.mesh.material.uniforms["u_waveLayers"].value[2].value.color.value = normalizeColor(state.color4);
});

let f1 = gui.addFolder("Functions");
f1.add(functions, "Save");
f1.add(functions, "Load");
f1.add(functions, "ApplyLoadedParameters");
f1.add(functions, "MakeScreenShot");
f1.add(functions, "RunCapture");
f1.add(functions, "StopCapture");
f1.add(functions, "SaveCapture");
f1.add(functions, "Play");
f1.add(functions, "Pause");
f1.add(functions, "Randomize");
