export default {
  pixelRatio: 1.0,
  width: 1024,
  height: 1024,

  innerWidth: 1024,
  innerHeight: 600,

  color1: 0x53df83,
  color2: 0x47d2e9,
  color3: 0x3f3f3f,
  color4: 0xeeeeee,

  skipFrames: false,

  captureFormat: "webm",
  FPSCapture: 60,
};
